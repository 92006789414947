import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"d-flex flex-column flex-grow-1 pa-4 mb-2",attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex align-start"},[_c('span',{staticClass:"text-subtitle-2 font-weight-bold d-block primary--text mb-2"},[_vm._v(_vm._s(_vm.task.subject))]),_c(VBtn,{staticClass:"ml-auto",attrs:{"rounded":"","outlined":"","small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('restore', _vm.task.id)}}},[_vm._v(" Pulihkan ")])],1),_c('div',{staticClass:"d-flex flex-wrap"},[_c('LimitElements',{attrs:{"elements":_vm.task.tag,"limit":2},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c(VChip,{staticClass:"mr-1",attrs:{"x-small":"","color":data.color}},[_vm._v(" "+_vm._s(data.name)+" ")])]}},{key:"others-activator",fn:function(ref){
var data = ref.data;
return [_c(VChip,{staticClass:"mr-1 v-chip-light-bg primary--text mb-2",attrs:{"x-small":"","color":"primary"}},[_vm._v(" +"+_vm._s(data.limit)+" ")])]}}])})],1),(_vm.task.description)?_c('span',{staticClass:"text-caption font-medium mb-1 mt-1"},[_vm._v(_vm._s(_vm.ellipsis(_vm.task.description, 76)))]):_vm._e(),(_vm.task.customer)?_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-caption text--disabled"},[_vm._v("Pelanggan")]),_c('router-link',{attrs:{"to":{ name: 'customer-detail', params: { id: _vm.task.customer.id } }}},[_c('span',{staticClass:"text-caption font-medium primary--text"},[_vm._v(_vm._s(_vm.task.customer.name))])])],1):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between align-end"},[_c('div',{staticClass:"d-flex flex-column mt-1"},[(_vm.task.end_date)?_c('span',{staticClass:"text-caption text--disabled"},[_vm._v("Tanggal Task Berakhir")]):_vm._e(),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-subtitle-2"},'span',attrs,false),on),[(_vm.task.end_date)?_c('span',{staticClass:"text-caption font-medium"},[_vm._v(_vm._s(_vm.dateFormat(_vm.task.end_date, 3)))]):_vm._e()])]}}])},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v("Dibuat: "+_vm._s(_vm.dateFormat(_vm.task.created_at, 3)))]),_c('span',[_vm._v("Diperbarui: "+_vm._s(_vm.dateFormat(_vm.task.updated_at, 3)))])])])],1)]),_c('div',{staticClass:"mt-2"},[_c('span',{staticClass:"text-caption text--disabled d-block"},[_vm._v("Dibuat oleh")]),_c('router-link',{attrs:{"to":{ name: 'user-detail', params: { id: _vm.task.created_by.id } }}},[_c('span',{staticClass:"text-caption font-medium primary--text"},[_vm._v(_vm._s(_vm.task.created_by.name))])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }