<template>
  <v-card
    width="400px"
    class="pa-3 pb-0"
  >
    <div class="d-flex justify-space-between">
      <span class="text-subtitle-2 font-weight-bold primary--text">Diarsipkan</span>
      <input-icon-toggle
        v-model="search"
        class="ms-2"
        :icon="icons.mdiMagnify"
        placeholder="Cari pelanggan diarsipkan"
        @close="closeSearch"
        @input="onSearch"
      />
    </div>
    <div
      id="archivedCustomer"
      v-scroll:#archivedCustomer="listenScrollFetchMore"
      class="list mt-2"
    >
      <ArchivedTaskCard
        v-for="task in taskList"
        :key="task.id"
        :task="task"
        @restore="restoreTaskFunc"
      />
    </div>
  </v-card>
</template>

<script>
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue'
import InputIconToggle from '@/components/inputs/InputIconToggle.vue'
import { apolloClient } from '@/vue-apollo'
import { archiveTask } from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import ArchivedTaskCard from '@/views/activity/task/components/ArchivedTaskCard.vue'
import useTask from '@/composables/useTask'

export default {
  components: {
    InputIconToggle,
    ArchivedTaskCard,
  },
  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const search = ref('')
    const isOpen = computed(() => props.isOpen)
    const filter = ref({ is_archive: true })
    const {
      taskList, fetchTasks, debouncedFetchTasks, listenScrollFetchMore,
    } = useTask({ filter, search })
    const closeSearch = () => {
      search.value = ''
      fetchTasks()
    }
    watch(isOpen, () => {
      fetchTasks()
    })
    const onSearch = value => {
      search.value = value
      debouncedFetchTasks()
    }
    onMounted(() => {
      fetchTasks()
    })
    const restoreTaskFunc = id => {
      Vue.$dialog({
        title: 'Pulihkan Task?',
        body: 'Yakin ingin pulihkan task?',
      }).then(result => {
        if (result) {
          apolloClient.mutate({
            mutation: archiveTask,
            fetchPolicy: 'no-cache',
            variables: {
              id,
              is_archive: false,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(() => {
            emit('update')
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil memulihkan task!',
            })
          }).catch(err => {
            errorHandling(err)
          })
        }
      })
    }

    return {
      fetchTasks,
      debouncedFetchTasks,
      onSearch,
      search,
      closeSearch,
      restoreTaskFunc,
      listenScrollFetchMore,
      taskList,
      icons: {
        mdiMagnify,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.list {
  @include style-scroll-bar();
  max-height: 60vh;
  overflow-y: scroll;
}
</style>
