<template>
  <v-card
    class="max-h-content-container position-relative overflow-hidden"
    :loading="loadingTasks"
  >
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="280"
      touchless
      mobile-breakpoint="sm"
      :temporary="$vuetify.breakpoint.smAndDown"
      absolute
      class="left-sidebar"
    >
      <task-left-sidebar
        @addTask="$refs.taskForm.show()"
        @filter="$refs.taskFilterModal.show()"
        @refetch="fetchTasks()"
      />
    </v-navigation-drawer>
    <div
      :style="{
        'margin-left': isLeftSidebarOpen && $vuetify.breakpoint.mdAndUp ? '280px' : null,
      }"
      class="position-relative h-full"
    >
      <div
        class="h-full"
      >
        <div class="pa-2 d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.smAndDown"
            class="mx-2"
            size="20"
            @click="isLeftSidebarOpen = !isLeftSidebarOpen"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <v-text-field
            v-model="taskFilter.search"
            placeholder="Cari task..."
            hide-details
            solo
            flat
            dense
            :prepend-inner-icon="$vuetify.breakpoint.mdAndUp ? icons.mdiMagnify : null"
            clearable
            @input="debouncedFetchTasks"
          />
          <v-menu offset-y>
            <template #activator="{ on }">
              <div
                class="d-flex align-center me-2 cursor-pointer"
                v-on="on"
              >
                <span
                  v-if="$vuetify.breakpoint.mdAndUp"
                  class="text-subtitle-2 me-2"
                >{{ state.filter.value.sort ? state.filter.value.sort.label : 'Sortir' }}</span>
                <v-icon
                  size="18"
                >
                  {{ icons.mdiUnfoldMoreHorizontal }}
                </v-icon>
              </div>
            </template>
            <v-card width="220px">
              <v-list
                dense
                nav
              >
                <v-list-item-group
                  v-model="state.filter.value.sort"
                  class="text--primary"
                  active-class="primary--text v-list-item--exact-active"
                  mandatory
                  @change="fetchTasks"
                >
                  <v-list-item
                    v-for="(sort, index) in sortOptions"
                    :key="index"
                    :value="sort"
                    :class="{ 'primary--text' : state.filter.value.sort === sort }"
                  >
                    <span class="text-subtitle-2">{{ sort.label }}</span>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
          <v-menu
            v-model="isArchiveOpen"
            :close-on-content-click="false"
            offset-y
            nudge-bottom="8px"
            nudge-left="20px"
            transition="slide-y-reverse-transition"
          >
            <template v-slot:activator="menu">
              <v-tooltip bottom>
                <template v-slot:activator="tooltip">
                  <v-icon
                    size="26"
                    class="me-3"
                    v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                    v-on="{ ...menu.on, ...tooltip.on }"
                    @click.stop
                  >
                    {{ icons.mdiArchive }}
                  </v-icon>
                </template>
                <span>Task dihapus / diarsipkan</span>
              </v-tooltip>
            </template>
            <ArchivedTask
              :is-open="isArchiveOpen"
              @update="fetchTasks()"
            />
          </v-menu>
          <v-btn-toggle
            v-model="viewTab"
            mandatory
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiTable }}</v-icon>
                </v-btn>
              </template>
              <span>Tampilan Tabel</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiCalendarMonth }}</v-icon>
                </v-btn>
              </template>
              <span>Tampilan Gantt Chart</span>
            </v-tooltip>
          </v-btn-toggle>
        </div>
        <v-divider />
        <v-tabs-items
          v-model="viewTab"
          class="rounded h-full"
        >
          <v-tab-item
            v-scroll.self="listenScrollFetchMore"
            class="scroll-list h-full"
          >
            <div
              v-if="taskList.length"
              id="listTask"
            >
              <draggable
                v-model="taskList"
                :sort="isDraggable"
                :delay="500"
                :delay-on-touch-only="true"
                class="h-100"
                :animation="200"
                :disabled="!isDraggable"
                @change="updatePosition($event)"
              >
                <task-card
                  v-for="(task, index) in taskList"
                  :key="`${task.id}${index}`"
                  :task="task"
                  @delete="deleteTask"
                  @check="checkTask(task.id, task.completed_at != null)"
                  @detail="$refs.taskDetail.show(task.id)"
                  @update="$refs.taskForm.update(task)"
                />
              </draggable>
              <div
                class="d-flex my-2"
                style="min-height: 48px"
              >
                <v-progress-circular
                  v-if="loadingFetchMore"
                  indeterminate
                  color="primary"
                  class="mx-auto"
                />
              </div>
            </div>

            <!-- if no task found -->
            <div
              v-else
              class="h-full d-flex flex-column align-center justify-center mt-n8"
            >
              <span class="text-subtitle-2 text--disabled mb-4">
                Belum Ada Task
              </span>
              <v-btn
                outlined
                text
                color="primary"
                class="mb-4"
                @click="$refs.taskForm.show()"
              >
                Buat Task Baru
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item
            class="h-full scroll-gantt gantt-container"
          >
            <ejs-gantt
              id="task-gantt"
              ref="gantt"
              :data-source="taskList"
              :task-fields="taskFields"
              :label-settings="labelSettings"
              :edit-settings="editSettings"
              :allow-reordering="true"
              :allow-resizing="true"
              :include-weekend="true"
              :action-begin="handleActionBegin"
              :enable-context-menu="true"
              :context-menu-items="contextMenuItems"
              height="100%"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <task-form
      ref="taskForm"
      @success="fetchTasks"
    />

    <task-filter
      ref="taskFilterModal"
      @close="fetchTasks"
    />

    <task-detail
      ref="taskDetail"
      @refetch="fetchTasks"
    />
  </v-card>
</template>

<script>
import {
  onMounted, computed, ref, watchEffect, watch,
} from '@vue/composition-api'
import {
  mdiChevronDoubleLeft, mdiChevronDoubleRight, mdiMagnify,
  mdiMenu, mdiUnfoldMoreHorizontal, mdiArchive, mdiChevronLeft,
  mdiChevronRight, mdiTable, mdiCalendarMonth,
} from '@mdi/js'
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
import { createFieldMapper } from 'vuex-use-fields'
import { getVuetify, useRouter } from '@/@core/utils'
import TaskLeftSidebar from './TaskLeftSidebar.vue'
import TaskCard from './TaskCard.vue'
import TaskForm from './TaskForm.vue'
import TaskFilter from './TaskFilter.vue'
import TaskDetail from './TaskDetail.vue'
import useTask from '@/composables/useTask'
import store from '@/store'
import ArchivedTask from '@/views/activity/task/components/ArchivedTask.vue'
import draggable from 'vuedraggable'
import { LexoRank } from 'lexorank'
// eslint-disable-next-line no-unused-vars
import useLexorank from '@/composables/useLexorank'
import { useEventListener, useStorage } from '@vueuse/core'
import {
  Edit,
  Selection,
  Reorder,
  Resize,
  ContextMenu,
} from '@syncfusion/ej2-vue-gantt'

const useFieldTask = createFieldMapper({ getter: 'task/getField', setter: 'task/setField' })

export default {
  components: {
    TaskLeftSidebar,
    TaskCard,
    TaskForm,
    TaskFilter,
    TaskDetail,
    ArchivedTask,
    draggable,
  },
  provide: {
    gantt: [Edit, Selection, Reorder, Resize, ContextMenu],
  },
  setup() {
    const { route } = useRouter()
    const taskDetail = ref(null)
    const isArchiveOpen = ref(false)
    const viewTab = useStorage('viewTabTask', 0)
    const { isLeftSidebarOpen } = useResponsiveLeftSidebar({ sidebarWidth: 280 })
    const state = {
      ...useFieldTask(['filter']),
    }
    const isDraggable = computed(() => {
      console.log(state.filter.value.sort.field)

      return state.filter.value.sort.field === 'lexorank'
    })
    const $vuetify = getVuetify()

    // Syncfusion Gantt Properties
    const gantt = ref(null)
    const taskFields = ref({
      id: 'id',
      name: 'subject',
      startDate: 'start_date',
      endDate: 'end_date',
    })
    const labelSettings = ref({
      leftLabel: 'subject',
    })
    const editSettings = ref({
      allowAdding: true,
      allowEditing: true,
      allowDeleting: true,
      showDeleteConfirmDialog: true,
      allowTaskbarEditing: true,
    })
    const handleActionBegin = args => {
      console.log(args.requestType)
      if (args.requestType === 'beforeOpenEditDialog') {
        // eslint-disable-next-line no-param-reassign
        args.cancel = true
        taskDetail.value.show(args.rowData.id)
      }
    }
    const contextMenuItems = ref([
      'AutoFitAll',
      'AutoFit',
      'TaskInformation',
      'DeleteTask',
      'Save',
      'Cancel',
      'SortAscending',
      'SortDescending',
      'Add',
      'Save',
      'Cancel',
    ])

    const computedTaskFilter = computed(() => {
      const filter = state.filter.value

      let isCompleted = null
      if (filter.status === 0) isCompleted = false
      if (filter.status === 1) isCompleted = true

      /* eslint-disable */
      return {
        sort: filter.sort.field,
        order: filter.sort.type,
        is_completed: isCompleted,
        assigned_to: filter.isAllUser
          ? filter.assignedTo.length
            ? filter.assignedTo.map(value =>value.id)
            : null
          : [store.state.auth.userData.id],
        tag_id: filter.tag.length
          ? filter.tag
          : null,
        end_date: filter.endAt.startDate !== null
          ? {
              date_min: filter.endAt.startDate,
              date_max: filter.endAt.endDate,
            }
          : null,
        completed_at: filter.completedAt.startDate !== null
          ? {
              date_min: filter.completedAt.startDate,
              date_max: filter.completedAt.endDate,
            }
          : null,
        customer: filter.customer.length
          ? filter.customer.map(el => el.id)
          : null,
      }
      /* eslint-enable */
    })

    const {
      sortOptions, loadingTasks, taskList, taskFilter, fetchTasks, deleteTask,
      checkTask, listenScrollFetchMore, listenScrollGanttFetchMore, loadingFetchMore, debouncedFetchTasks, timestamp,
    } = useTask({
      filter: computedTaskFilter,
    })

    const {
      updateObjectLexorank,
    } = useLexorank({
      objectType: 'task',
    })

    const updatePosition = ({ moved }) => {
      let newLexorank = ''
      if (moved.newIndex <= 0) {
        newLexorank = LexoRank.parse(taskList.value[1].lexorank).genPrev()
      } else if (moved.newIndex >= taskList.value.length - 2) {
        newLexorank = LexoRank.parse(taskList.value[taskList.value.length - 2].lexorank).genNext()
      } else {
        const prevDataLexorank = LexoRank.parse(taskList.value[moved.newIndex - 1].lexorank)
        const nextDataLexorank = LexoRank.parse(taskList.value[moved.newIndex + 1].lexorank)
        newLexorank = prevDataLexorank.between(nextDataLexorank)
      }
      updateObjectLexorank(newLexorank.value, moved.element.id).then(() => {
        fetchTasks()
      })
    }

    const setTheme = async () => {
      if ($vuetify.theme.dark) {
        document.getElementById('theme').href = '/syncfusion-theme/gantt/ej2-gantt-dark.css'
      } else {
        document.getElementById('theme').href = '/syncfusion-theme/gantt/ej2-gantt-light.css'
      }
    }

    watch($vuetify, () => {
      setTheme()
    })

    watchEffect(() => {
      if ($vuetify.breakpoint.mdAndUp) isLeftSidebarOpen.value = true
      else isLeftSidebarOpen.value = false
    })

    watch(gantt, () => {
      console.log('gantt ref changed')
      const element = document.querySelector('.e-chart-scroll-container')
      useEventListener(element, 'scroll', ev => {
        listenScrollGanttFetchMore(ev)
      })
    })

    const onScroll = () => {
      console.log('an;jing')
    }

    onMounted(async () => {
      setTheme()
      // useEventListener(document.querySelector('.e-chart-scroll-container'), 'scroll', ev => {
      //   listenScrollGanttFetchMore(ev)
      // })
      const date = new Date()
      timestamp.value = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString()
      console.log(timestamp.value)
      fetchTasks()
      if (route.value.params.id) {
        taskDetail.value.show(route.value.params.id)
      }
    })

    return {
      isArchiveOpen,
      state,
      gantt,
      isLeftSidebarOpen,
      sortOptions,
      loadingTasks,
      taskList,
      taskDetail,
      taskFilter,
      loadingFetchMore,
      taskFields,
      labelSettings,
      editSettings,
      contextMenuItems,

      listenScrollGanttFetchMore,
      handleActionBegin,
      fetchTasks,
      deleteTask,
      checkTask,
      debouncedFetchTasks,
      listenScrollFetchMore,
      onScroll,

      icons: {
        mdiMagnify,
        mdiMenu,
        mdiChevronLeft,
        mdiChevronRight,
        mdiUnfoldMoreHorizontal,
        mdiArchive,
        mdiChevronDoubleLeft,
        mdiChevronDoubleRight,
        mdiTable,
        mdiCalendarMonth,
      },
      updatePosition,
      isDraggable,
      viewTab,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.scroll-list {
  @include style-scroll-bar();
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 120px);
}

.scroll-gantt {
  @include style-scroll-bar();
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 120px);
}

.e-content {
  @include style-scroll-bar();
  overflow-x: scroll;
  height: calc(100% - 120px);
}

</style>
