<template>
  <div
    class="left-sidebar d-flex flex-column h-full"
  >
    <div class="pa-4 d-flex flex-column">
      <v-btn
        v-if="$can('create', 'Task')"
        color="primary"
        class="mb-4"
        @click="$emit('addTask')"
      >
        Task Baru
      </v-btn>
      <v-btn
        color="primary"
        text
        outlined
        @click="$emit('filter')"
      >
        Filter
      </v-btn>
    </div>

    <p class="text-xs text--disabled mt-2 mx-4 mb-1">
      Status Task
    </p>
    <v-list
      dense
      flat
    >
      <v-list-item-group
        v-model="state.filter.value.status"
        class="text--primary"
        active-class="primary--text v-list-item--exact-active"
        mandatory
        @change="$emit('refetch')"
      >
        <template
          v-for="status in taskStatusFilter"
        >
          <v-list-item
            :key="status.id"
            active-class="primary--text v-list-item--exact-active"
          >
            <v-list-item-icon class="me-2">
              <v-badge
                :color="status.color"
                dot
                inline
                class="align-self-center label-dot"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ status.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>

    <v-card
      class="py-2 px-3 mt-auto mx-4"
      style="margin-bottom: 80px"
      outlined
    >
      <div class="d-flex align-center justify-space-between">
        <span class="text-subtitle-2">Semua Pengguna</span>
        <v-switch
          v-model="state.filter.value.isAllUser"
          class="ma-0 mr-n3"
          hide-details
          dense
          inset
          @change="$emit('refetch')"
        />
      </div>
      <span class="text-caption text--disabled">Lihat semua task dari semua pengguna di workspace ini.</span>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { createFieldMapper } from 'vuex-use-fields'

const useFieldTask = createFieldMapper({ getter: 'task/getField', setter: 'task/setField' })

export default {
  setup() {
    const state = {
      ...useFieldTask(['filter']),
    }

    const viewTab = ref(0)
    const taskStatusFilter = [
      {
        id: 1, name: 'Belum Selesai', value: 0, color: 'warning',
      },
      {
        id: 3, name: 'Sudah Selesai', value: 1, color: 'success',
      },
      {
        id: 2, name: 'Semua', value: 2, color: 'primary',
      },
    ]

    return {
      state,
      viewTab,
      taskStatusFilter,
    }
  },
}
</script>

<style lang="scss">
.left-sidebar {
  .v-list--dense .v-list-item {
    height: 38px;
    min-height: 38px;
    border-left: 4px solid transparent;

    &.v-list-item--exact-active {
      border-color: var(--v-primary-base);
    }
    .v-list-item__icon {
      align-items: end;
    }
    // label dot
    .label-dot {
      .v-badge__badge {
        width: 12px !important;
        height: 12px !important;
        border-radius: 10px;
      }
    }
  }
}
</style>
