<template>
  <v-card
    class="d-flex flex-column flex-grow-1 pa-4 mb-2"
    outlined
  >
    <div class="d-flex align-start">
      <span class="text-subtitle-2 font-weight-bold d-block primary--text mb-2">{{ task.subject }}</span>
      <v-btn
        rounded
        outlined
        small
        text
        class="ml-auto"
        @click.stop="$emit('restore', task.id)"
      >
        Pulihkan
      </v-btn>
    </div>
    <div class="d-flex flex-wrap">
      <LimitElements
        :elements="task.tag"
        :limit="2"
      >
        <template #default="{ data }">
          <v-chip
            x-small
            :color="data.color"
            class="mr-1"
          >
            {{ data.name }}
          </v-chip>
        </template>
        <template #others-activator="{ data }">
          <v-chip
            x-small
            color="primary"
            class="mr-1 v-chip-light-bg primary--text mb-2"
          >
            +{{ data.limit }}
          </v-chip>
        </template>
      </LimitElements>
    </div>
    <span
      v-if="task.description"
      class="text-caption font-medium mb-1 mt-1"
    >{{ ellipsis(task.description, 76) }}</span>
    <div
      v-if="task.customer"
      class="d-flex flex-column"
    >
      <span class="text-caption text--disabled">Pelanggan</span>
      <router-link :to="{ name: 'customer-detail', params: { id: task.customer.id } }">
        <span class="text-caption font-medium primary--text">{{ task.customer.name }}</span>
      </router-link>
    </div>
    <div class="d-flex justify-space-between align-end">
      <div class="d-flex flex-column mt-1">
        <span
          v-if="task.end_date"
          class="text-caption text--disabled"
        >Tanggal Task Berakhir</span>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="text-subtitle-2"
              v-bind="attrs"
              v-on="on"
            >
              <span
                v-if="task.end_date"
                class="text-caption font-medium"
              >{{ dateFormat(task.end_date, 3) }}</span>
            </span>
          </template>
          <div class="d-flex flex-column">
            <span>Dibuat: {{ dateFormat(task.created_at, 3) }}</span>
            <span>Diperbarui: {{ dateFormat(task.updated_at, 3) }}</span>
          </div>
        </v-tooltip>
      </div>
      <!-- <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-if="task.priority"
            small
            :color="priorityColor(task.priority.id)"
            v-bind="attrs"
            v-on="on"
          >
            {{ task.priority.name }}
          </v-chip>
        </template>
        <span>Prioritas Job</span>
      </v-tooltip> -->
    </div>
    <div class="mt-2">
      <span class="text-caption text--disabled d-block">Dibuat oleh</span>
      <router-link :to="{ name: 'user-detail', params: { id: task.created_by.id } }">
        <span class="text-caption font-medium primary--text">{{ task.created_by.name }}</span>
      </router-link>
    </div>
  </v-card>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import {
  mdiDotsVertical, mdiAccountPlus,
} from '@mdi/js'
import { ellipsis, avatarText } from '@core/utils/filter'
import dateFormat from '@/utils/dateFormat'
import LimitElements from '@/components/misc/LimitElements.vue'

export default {
  components: {
    LimitElements,
  },
  props: {
    task: {
      type: Object,
      default: null,
    },
    isKanban: {
      type: Boolean,
      default: false,
    },
    isArchive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    console.log(props.task)
    const showMenu = ref(false)
    onMounted(() => console.log('mounted'))

    return {
      ellipsis,
      avatarText,
      showMenu,
      dateFormat,

      icons: {
        mdiDotsVertical,
        mdiAccountPlus,
      },
    }
  },
}
</script>
