/* eslint-disable no-nested-ternary */
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import { apolloClient } from '@/vue-apollo'
import {
  updateLexorank,
} from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'

// eslint-disable-next-line no-empty-pattern
const useLexorank = ({
  objectType,
} = {}) => {
  const loadingLexorank = ref(false)
  const workspaceId = store.getters.getCurrentWorkspaceId

  const updateObjectLexorank = (lexorank, id) => {
    loadingLexorank.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: updateLexorank,
        variables: {
          lexorank,
          Job_id: objectType === 'Job' ? id : null,
          prospect_id: objectType === 'prospect' ? id : null,
          task_id: objectType === 'task' ? id : null,
          workspace_id: workspaceId,
        },
      }).then(() => {
        Vue.notify({
          title: 'Sukses',
          text: `Berhasil mengubah urutan ${objectType}!`,
        })
        loadingLexorank.value = false
        resolve()
      }).catch(err => {
        reject()
        errorHandling(err, 'Update Lexorank')
      })
    })
  }

  return {
    updateObjectLexorank,
    loadingLexorank,
  }
}
export default useLexorank
